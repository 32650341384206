import React, { Component } from 'react';
import './Style.css';
import './App.css';
import Footer from './Footer';
import './Site_name';

class Allergies extends Component {
  constructor(){
    super();
    this.state={
      slider_imgs:[],
      site:global.site_name,
    }
  }

  componentDidMount() {
  
  fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/4`).then((resp)=>{
  resp.json().then((result)=>{
    this.setState({ slider_imgs: result.data.header_slider_image});
  })
})
}

    render() {
      const {slider_imgs} = this.state;
        return (
          <div>
            <div className=" overflow-auto box-height bg-white" >
              
              <div className="container-fluid" id="banner_section" >
    <div className="row">
    <div className="col-md-12 col-12">
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
    <div className="carousel-inner">
    {slider_imgs.map((slider_img, index) =>
      <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
      <img className="d-block w-100 carousel-height"  src={` https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
      {/* <div class="carousel-caption d-none d-md-block">
      <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={3000} className="title title1 animated slider_content fadeInUp" style={{textAlign: 'center', color: '#ffffff', backgroundColor: '#c0c0c0'}}>{slider_img.all_title_name}</h3>
      </div> */}
      </div>
      )}
      </div>
      {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
      </div>
      
      </div>
      </div>
      </div>
            <div className="container-fluid  para p-5" style={{background:'#f9b270'}}>
                <div className="row">
                        <div className="col-md-8 col-12 mt-5">
                        <div id="carouselExampleControls2" className="carousel slide" data-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
     <div className="swin-sc swin-sc-title text-left">
     <div>
  <p className="top-title"><span>India Dining Services</span></p>
  <h3 className="big_font_coman text-white">Common Allergens</h3>
  <div className="team-info clearfix mt-5">
    <p className="team-name" style={{fontSize: 21,textTransform:'uppercase'}}>Peanut • Tree Nut • Fish • Shellfish • Soy • Egg • Wheat • Dairy &amp; Milk</p>
  </div>
</div>

     </div>
    </div>
    <div className="carousel-item">
    <div className="swin-sc swin-sc-title text-left">
  <h3 className="big_font_coman text-white" style={{textTransform:'capitalize'}}>Guide to Managing Food Allergies &amp; Intolerances</h3>
 <div className="team-info clearfix mt-3">
  <p className="team-description">We understand the seriousness of food allergies and that people with food allergies have to exert more effort in managing their diets than their peers without allergies, and experience a more limited selection.</p>
  <p className="team-description">That is why we support our employees with food allergies by providing the knowledge and resources that are necessary to make informed food choices in our dining locations.</p>
  <p className="team-description">Our goal is to provide our employees with the tools and support they need to utilize the café safely and be active in the management of their food allergy or intolerance.</p>
</div>

</div>

    </div>
    <div className="carousel-item">
    <div className="swin-sc swin-sc-title text-left">
  <h3 className="text-white big_font_coman" style={{textTransform:'capitalize'}}>What is a food allergy</h3>
  <p>A food allergy is when the body's immune system reacts unusually to specific foods.</p>

  <div className="team-info clearfix" style={{color: '#000', marginTop: '0px'}}>
  <h2> Symptoms of a food allergy</h2>
  <h5 className="about-content para"><i className="fa fa-hand-o-right" style={{fontSize: 22, paddingRight: 10, color: '#ffffff'}} />An itchy sensation inside the mouth, throat or ears</h5>
  <h5 className="about-content para"><i className="fa fa-hand-o-right" style={{fontSize: 22, paddingRight: 10, color: '#ffffff'}} />A raised itchy red rash (urticaria, or "hives")</h5>
  <h5 className="about-content para"><i className="fa fa-hand-o-right" style={{fontSize: 22, paddingRight: 10, color: '#ffffff'}} />Swelling of the face, around the eyes, lips, tongue and roof of the mouth</h5>
  <h5 className="about-content para"><i className="fa fa-hand-o-right" style={{fontSize: 22, paddingRight: 10, color: '#ffffff'}} />Vomiting</h5>
  <h5 className="about-content para"><i className="fa fa-hand-o-right" style={{fontSize: 22, paddingRight: 10, color: '#ffffff'}} /><span className="text-orange">Anaphylaxis </span> (Breathing difficulties, lightheadedness, loss of consciousness, etc.).This is the <span className="text-orange">most serious form of food allergy and needs immediate medical attention.</span></h5>
</div>

</div>

    </div>
  </div>
  <a className="carousel-control-prev  allergies-prev-icon" href="#carouselExampleControls2" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true" />
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next  allergies-next-icon" href="#carouselExampleControls2" role="button" data-slide="next">
    <span className="carousel-control-next-icon  allergies-next-icon" aria-hidden="true" />
    <span className="sr-only">Next</span>
  </a>
</div>
                        </div>

                        <div className="col-md-4 col-12 text-center">
                        <div className="img-chef"><img className="img-fluid allergies_img" src=" https://admin.foodweb.ksoftpl.com/assets/images/Logo/food_Allergy_awareness_poster.jpg"/></div>

                        </div>
                </div>
            </div>
            <div style={{textAlign:'center'}} className="pt-5">
            <p style={{background: '#fff', marginBottom: 0, textAlign: 'center', paddingLeft:'10px', paddingRight:'10px'}} className="para"><b>Source: </b><a href="https://www.mayoclinic.org/diseases-conditions/food-allergy/symptoms-causes/syc-20355095" target="_blank">https://www.mayoclinic.org/diseases-conditions/food-allergy/symptoms-causes/syc-20355095</a></p>


                                </div>
                
            </div>
            <Footer />
            </div>
        );
    }
}

export default Allergies;