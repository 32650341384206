import React, { Component } from 'react';
import './Style.css'
import Footer from './Footer';
import $ from 'jquery';
import {Link } from 'react-router-dom';
import Counter from './Counter';
import './Site_name';


class Home extends Component {
    
    constructor(){
        super();
        this.state={
            counters:[],
            discover_imgs:[],
            slider_imgs:[],
            site:global.site_name,
        }
    }
    
  
    componentDidMount() {
        <script>
        $(document).ready(function(){  
            $(".slider_content").fadeIn()
        });
        </script>
        
        fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/home/${this.state.site}`).then((resp)=>{
        resp.json().then((result)=>{
            this.setState({ counters: result.data.meal_counter});
            // console.log(result.data.meal_counter);
            this.setState({ discover_imgs: result.data.counter});
            this.setState({ slider_imgs: result.data.header_slider_image});
            
        })
    })
}

clickMe(discover_img){
    alert(discover_img.co_id);
    
}


render(){
    const {counters,discover_imgs,slider_imgs} = this.state;
    // console.warn(counters);
    return (
        <div>
        <div className="overflow-auto box-height bg-white">
        
        <div className="container-fluid" id="banner_section" >
        <div className="row">
        <div className="col-md-12 col-12">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
        <div className="carousel-inner">
        {slider_imgs.map((header_slider_image, index) => 
            <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
            <img className="d-block carousel-height w-100" src={` https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${header_slider_image.all_slider_images}`} alt="First slide" />
            <div className={`carousel-caption  d-md-block header_slider_position_${header_slider_image.all_text_format}`}>
                
                
                    <h3  data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={500} className={`title title1 animated slider_content fadeInUp header_slider_text_${header_slider_image.all_text_align} header_slider_position_${header_slider_image.all_text_format}`} style={{color: '#ffffff', backgroundColor: '#c0c0c0'}}>{header_slider_image.all_title_name}</h3> 
                    
                  {/* {
                    header_slider_image.all_text_align==2? 
                    <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={500} className="title title1 animated slider_content fadeInUp text_align_center" style={{color: '#ffffff', backgroundColor: '#c0c0c0'}}>{header_slider_image.all_title_name}</h3> 
                    :null 
                }
                  {
                    header_slider_image.all_text_align==3? 
                    <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={500} className="title title1 animated slider_content fadeInUp text_align_right" style={{color: '#ffffff', backgroundColor: '#c0c0c0'}}>{header_slider_image.all_title_name}</h3> 
                    :null 
                } */}
                

                
                
            
            </div>
            </div>
            )}
            </div>
            {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
           
            </div>
            </div>
            </div>
            </div>
            
            
            
            {/* main meal counter */}
            {/* <div>
                <ul className="p-5 bg-primary text-white">
                {counters.map(counter => <li>{counter.co_id}</li>)}
                <li>{counters.m_meal_breakfast_time}</li> 
                </ul>
            </div> */}
            
            <div className="container-fluid contain_space mt-4">
            <div className="swin-sc swin-sc-title" style={{marginBottom: 10}}>
            <p className="top-title"><span className="top_heading" style={{color: '#000!important', lineHeight: 3, fontWeight: 'bold'}}>Main Meal Counters Operational Hrs</span></p>
            
            <div className="container contain_space">
            <div className="row">
            
            <div className="col-md-1 col-12 mt-2 ">
            
            </div>
            
            <div className="col-md-2 col-12 mt-2 shadow p-1 mb-1 bg-white rounded p-4">
            <p className="para fa para_space">Breakfast</p>
            <p className="para para_space">{counters.m_meal_breakfast_time}</p>
            </div>
            
            <div className="col-md-2 col-12 mt-2 shadow p-1 mb-1 bg-white rounded ml-1 p-4">
            <p className="para fa para_space">Lunch</p>
            <p className="para para_space">{counters.m_meal_lunch_time}</p>
            </div>
            
            <div className="col-md-2 col-12 mt-2 shadow p-1 mb-1 bg-white rounded ml-1 p-4">
            <p className="para fa para_space">Evening Snacks</p>
            <p className="para para_space">{counters.m_meal_evening_snacks_time}</p>
            </div>
            
            <div className="col-md-2 col-12 mt-2 shadow p-1 mb-1 bg-white rounded ml-1 p-4">
            <p className="para fa para_space">Dinner</p>
            <p className="para para_space">{counters.m_meal_dinner_time}</p>
            </div>
            
            <div className="col-md-2 col-12 mt-2 shadow p-1 mb-1 bg-white rounded ml-1 p-4">
            <p className="para fa para_space">Midnight Snacks</p>
            <p className="para para_space">{counters.m_meal_midnight_time}</p>
            </div>
            
            
            </div>
            </div>
            </div>
            
            </div>
            
            {/* discover code start */}
            <div className="container-fluid contain_space mt-5 mb-5" style={{marginTop:"100px"}}>
            <div className="swin-sc swin-sc-title" style={{marginBottom: 10,color:'#000'}}>
            <p className="top-title"><span style={{color: '#000!important',fontSize:'20px', fontWeight: 'bold'}}>DISCOVER</span></p>
            <h3 className="my_big_head2">What's Cooking !</h3>
            
            
            <div className="container contain_space mt-5">
            <div className="row">
            
            {
                discover_imgs.map(discover_img=>
                    
                    
                        <Link to={`/Counter/${discover_img.co_id}`} className="col-md-3 col-6 myimg mt-2" style={{paddingTop: '10px', paddingBottom: '10px'}} key={discover_img.co_id}>
                    
                    <img src={` https://admin.foodweb.ksoftpl.com/assets/vendors_logo/${discover_img.vendor_image}`} className="img-fluid meal_counter_logo" />
                    </Link>
                   
                    )
                }
                
                
                
                
                </div>
                
                
                </div>
                </div>
                
                </div>
                
                </div>
                <Footer />
                </div>
                );
            }
        }
        
        export default Home;
        
