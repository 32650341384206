import './App.css';
import './Style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Menu from './Menu';
import Glutenfree from "./Glutenfree";
import Allergies  from "./Allergies";
import Sustainability from './Sustainability';
import Pantryservice from './Pantryservice';
import Healthy_Recipes from "./Healthy_Recipes";
import Nutrition from './Nutrition';
import Example from "./Example";
import Home from './Home';
import $ from 'jquery';
import Buffet from './Buffet';
import Boxed_lunch from './Boxed_lunch';
import Special_recipes from './Special_recipes';
import Counter from './Counter';
import React, { Component } from 'react';
import {BrowserRouter,Route,Switch,HashRouter} from 'react-router-dom';
import './Site_name';


class App extends Component {

    constructor(){
      super();
      this.state={
        banner:[],
        site:global.site_name,
      }
    }

    componentDidMount() {
   
      fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/home/${this.state.site}`).then((resp)=>{
          resp.json().then((result)=>{
              // console.warn(result)
              this.setState({ banner: result.data.background_image});
          })
      })

      $(document).ready(function(){
        $("#totop").hide();
        $(function toTop() {
            $(window).scroll(function () {
                if ($(this).scrollTop() > 100) {
                    $('#totop').fadeIn();
                } else {
                    $('#totop').fadeOut();
                }
            });
    
            $('#totop').click(function () {
                $('body,html').animate({
                    scrollTop: 0
                }, 800);
                return false;
            });
        });         
        });
  }
   
   render(){
     const {banner} = this.state;
    //  console.warn(banner);
     return(
      <HashRouter>
<div id="section_top" className="banner-bg short-banner" style={{ 
  backgroundImage: `url(" https://admin.foodweb.ksoftpl.com/assets/background_images_banner/${banner.mbi_image}")`
 }}>
    <div className="section_top">
    <Menu />
      <Switch >
      <Route path="/" exact component={Home}/>
        <Route path="/Sustainability" exact component={Sustainability}/>
        <Route path="/Glutenfree"  component={Glutenfree}/>
        <Route path="/Allergies"  component={Allergies}/>
        <Route path="/Pantryservice"  component={Pantryservice}/>
        <Route path="/Healthy_Recipes"  component={Healthy_Recipes}/>
        <Route path="/Boxed_lunch"  component={Boxed_lunch}/>
        <Route path="/Buffet"  component={Buffet}/>
        <Route path="/Special_recipes" component={Special_recipes}/>
        <Route path="/Counter/:co_id"  component={Counter}/>
        <Route path="/Example"  component={Example}/>
        <Route path="/Nutrition"  component={Nutrition}/>
                
      </Switch>
    </div>
    {/* <div id="totop" onClick="toTop()"  className="animated zoomIn" style={{display: 'inline',scrollBehavior:'smooth',cursor:'pointer'}}><i className="fa fa-angle-double-up" /></div> */}
    <div id="totop" className="animated zoomIn" style={{display: 'inline',scrollBehavior:'smooth',cursor:'pointer'}}><i className="fa fa-angle-double-up" /></div>
 

</div>

</HashRouter>
     )
   }
  }


export default App;


