import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import './Style.css';
import './Site_name';

class Buffet extends Component {
      
      
      constructor(){
            super();
            this.state={
                  boxes:[],
                  slider_imgs:[],
                  site:global.site_name,
            }
      }
      
      
      componentDidMount() {
            <script>
            $(document).ready(function(){  
                  $(".slider_content").fadeIn()
            });
            </script>
            
            fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/get_buffet_details/${this.state.site}`).then((resp)=>{
            resp.json().then((result)=>{
                  // console.warn(result.data);
                  this.setState({ boxes: result.data.buffet_details});
                  
            })
      })
      
      fetch(`https://admin-foodweb-laravel.ksoftpl.com/api/header_slider_images/${this.state.site}/7`).then((resp)=>{
      resp.json().then((result)=>{
            this.setState({ slider_imgs: result.data.header_slider_image});
      })
})
}


render() {
      const {boxes, slider_imgs} = this.state;
      const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

      return (
            <div>
            <div className="overflow-auto box-height bg-white">
            <div className="container-fluid" id="banner_section">
            <div className="row">
            <div className="col-md-12 col-12">
            <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
            {slider_imgs.map((slider_img, index) =>
                  <div className={`${index == 0 ? "carousel-item active" : "carousel-item"}`}>
                  <img className="d-block w-100 carousel-height" src={` https://admin.foodweb.ksoftpl.com/assets/slider_banner_images/${slider_img.all_slider_images}`} alt="First slide" />
                  <div className={`carousel-caption  d-md-block header_slider_position_${slider_img.all_text_format}`}>
                  <h3 data-ani-in="fadeInUp" data-ani-out="fadeOutDown" data-ani-delay={500} className={`title title1 animated slider_content fadeInUp header_slider_text_${slider_img.all_text_align} header_slider_position_${slider_img.all_text_format}`} style={{color: '#ffffff', backgroundColor: '#c0c0c0'}}>{renderHTML(slider_img.all_title_name)}</h3>
                  </div>
                  </div>
                  )}
                  </div>
                  {
              slider_imgs.length >1 ?   
              <>
              <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true" />
              <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true" />
              <span className="sr-only">Next</span>
              </a>
              </>
              : null
            }
                  </div>
                  
                  </div>
                  </div>
                  </div>
                  
                  <div className="container-fluid contain_space mt-5 mb-5">
                  
                  <div className="row">
                  <ul className="nav ">
                  {/* <li className="col-md-3 col-3"><a data-toggle="pill" href="#western"><img src="./assets/images/icon/Western.jpg" className="img-fluid w-100 box_img" /><p className="box_heading">western</p></a></li> */}
                  {boxes.map(buffet_details => 
                        <li className="col-md-2 col-4" style={{padding: '0px 10px'}}><a data-toggle="pill" href={`#${buffet_details.h_id}`}><img src={` https://admin.foodweb.ksoftpl.com/assets/buffet/${buffet_details.h_navbar_image}`} className="box_image img-fluid w-100" alt="" /><p className="box_heading">{buffet_details.h_title}</p></a></li>
                        )}
                        </ul>
                        </div>
                        {boxes.map((buffet_details, index) => {
                              const menu_ingredient = JSON.parse(buffet_details.h_menu_ingredient);
                              return(
                                    <div className={`${index == 0 ? "tab-content row tab-pane fade in active mt-5 show" : "tab-content row tab-pane fade in active mt-5"}`} style={{display: 'none'}} id={buffet_details.h_id}>
                                    <div className="col-md-6 col-sm-12">
                                    <div className="cat-wrapper">
                                    <div className="item"><img src={` https://admin.foodweb.ksoftpl.com/assets/buffet/${buffet_details.h_banner_image}`} alt="" className="img  img-responsive img-full img-fluid align-middle " /></div>
                                    </div>
                                    </div>
                                    
                                    <div className="col-md-6 col-sm-12">
                                    <div className="products  blog-item" style={{padding: 15}}>
                                    <div className="row">
                                    <div className="col-md-8 col-8">
                                    <h1 className="para recipe-heading fa" >{buffet_details.h_title} </h1>
                                    </div>
                                    <div className="col-md-4 col-4">
                                    <h2 className="orrange_heading" style={{fontFamily:'Rancho, sans-serif',fontSize:'22px'}}>PHP {buffet_details.h_price}++</h2>
                                    </div>
                                    </div>
                                    <hr/>
                                    <div className="item product-01 pro">
                                    {
                                          menu_ingredient.map(recipes => 
                                                <h5 className="para_box">{renderHTML(recipes)}</h5>
                                                )}
                                                </div>
                                                
                                                </div>
                                                </div>
                                                {/* <div id="home" className="tab-pane fade in active">
                                                <h3>Western</h3>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                          </div> */}
                                          {/* <div id="menu1" className="tab-pane fade">
                                          <h3>Indo Asian</h3>
                                          <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                    </div> */}
                                    {/* <div id="menu2" className="tab-pane fade">
                                    <h3>Indian</h3>
                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
                                    </div>
                                    <div id="menu3" className="tab-pane fade">
                                    <h3>Indo Western</h3>
                                    <p>Eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                              </div> */}
                              </div>
                              )})}
                              </div>
                              
                              
                              <div className="container-fluid contain_space mt-5 mb-5">
                              <div className="row">
                              <div className="col-md-12 col-sm-12">
                              <div className="products  blog-item" style={{padding: 28}}>
                              <div className="product-01 pro">
                              <p className="para" style={{fontFamily: '"Merriweather Sans", sans-serif', fontWeight: 'bold'}}>Dining services works hard towards bringing together culinary excellence and outstanding service, creating the perfect catering experience for your events.<br />You can now book your packed box and catering service by accessing <a href="https://vems.jpmchase.net/virtualEMS/" style={{textDecoration: 'underline'}}>EMS</a> (Event Management System) or copy and past the link into your browser’s address bar.</p>
                              {/*<p style="font-family: 'Merriweather Sans', sans-serif;" >EMS Catering Order <a href="#" style="text-decoration: underline;">User Guide</a></p>*/}
                              </div>
                              </div>
                              </div>
                              </div>
                              
                              </div>
                              </div>
                              <Footer />
                              </div>
                              );
                        }
                  }
                  
                  export default Buffet;